<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'app',
  components: {

  }
}
</script>

<style lang="less">
html,
body,
#app {
  height: 100%;
  margin: 0;
  padding: 0;
}


.el-breadcrumb {
  margin-bottom: 15px;
  font-size: 12px;
}

.el-row {
  margin-top: 20px;
}
 
</style>
