import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    name: 'index',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    redirect: '/welcome',
    children:[
      {
        path: '/welcome',
        name: 'Welcome',
        component: () => import('../views/Welcome.vue')
      },
      {
        path: '/sys/role',
        name: 'Role',
        component: () => import('../views/sys/Role.vue')
      },
      {
        path: '/sys/auth',
        name: 'Auth',
        component: () => import('../views/sys/Auth.vue')
      },
      {
        path: '/sys/manager',
        name: 'Manager',
        component: () => import('../views/sys/Manager.vue')
      }

      ,
      {
        path: '/goods/cat',
        name: 'Cat',
        component: () => import('../views/goods/Cat.vue')
      }
      ,
      {
        path: '/goods/list',
        name: 'Goods',
        component: () => import('../views/goods/Goods.vue')
      },
      {
        path: '/goods/add',
        name: 'GoodsAdd',
        component: () => import('../views/goods/GoodsAdd.vue')
      },
      {
        path: '/user/list',
        name: 'User',
        component: () => import('../views/user/User.vue')
      },
      {
        path: '/order/list',
        name: 'User',
        component: () => import('../views/order/Order.vue')
      }
    ]
  },



  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomeView
  // },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  routes
})

export default router
