import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import './assets/fonts/iconfont.css'
import './assets/font_pmdge31ug08/iconfont.css'
import { Message } from 'element-ui'
import Plugin from 'v-fit-columns'
Vue.use(Plugin)

Vue.config.productionTip = false
import axios from 'axios'
let baseURL=  'https://pp.kcclass.fun/admin'
// let baseURL=  'http://localhost:8082'

Vue.prototype.$baseURL=baseURL
axios.defaults.baseURL =baseURL
// axios.defaults.withCredentials=true

let $request=function request({headers, data, method, url,params, failcall }) {
  return new Promise((resolve, reject) => {
    axios({
      method: method || 'GET',
      url: url,
      headers: headers?headers: {
        'Content-Type': 'application/json',
        'token': sessionStorage.getItem('token')
      },
      data: data,
      params:params
    })
      .then((response) => {
        if (response.data.code === 200) {
          // Message.success(response.data.message);
          resolve(response.data);
        }else if(response.data.code === -2){
          Message.error(response.data.message);
          router.push("/login")
        }else {
          if (failcall) {
            failcall(response.data)
          } else {
            Message.error(response.data.message);
          }
          reject(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
        Message.error('服务器异常');
        reject(error);
      });
  });
}

Vue.prototype.$request = $request
Vue.prototype.$post = function post({ data, url,params, failcall }){
  let method = 'POST';
  return $request({ data, method,url,params, failcall });
}

Vue.prototype.$get = function get({ data, url,params, failcall }){
  let method = 'GET';
  return $request({ data, method,url,params, failcall });
}

Vue.prototype.$upload = function get({ data, url,params, failcall }){
  let method = 'POST';
  let headers ={
    'Content-Type':  'multipart/form-data',
    'token': sessionStorage.getItem('token')
  }
  return $request({headers, data, method,url,params, failcall });
}


//需要从后端请求到拥有的权限项，这里直接数组代替
sessionStorage.setItem('auths', JSON.stringify(["/role/add","/role","/auth","/sys"]));
Vue.prototype.$show = function checkAuth(auth) {
  let auths = JSON.parse(sessionStorage.getItem('auths'));
  // return auths.includes(auth);
  return true;
}



let vm = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
