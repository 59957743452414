import Vue from 'vue'


import { Button,
    Form,
    FormItem,
    OptionGroup,
    Divider,
    Input,
    Radio,
    RadioGroup,
    RadioButton,
    Message,
    Container,
    Header,
    Aside,
    Main,
    Menu,
    Submenu,
    MenuItem,
    Breadcrumb,
    BreadcrumbItem,
    Card,
    Row,
    Col,
    Table,
    TableColumn,
    Switch,
    Tooltip,
    Pagination,
    Dialog,
    MessageBox,
    Tag,
    Tree,
    Select,
    Option,
    Cascader,
    Alert,
    Tabs,
    TabPane,
    Steps,
    Step,
    CheckboxGroup,
    Checkbox,
    Upload,
    Loading ,
    Timeline,
    Image,
    TimelineItem } from 'element-ui'


    Vue.use(Button)
    Vue.use(OptionGroup)
    Vue.use(Divider)
    Vue.use(RadioButton)
    Vue.use(Radio)
    Vue.use(Form)
    Vue.use(FormItem)
    Vue.use(Input)
    Vue.use(Container)
    Vue.use(Input)
    Vue.use(Header)
    Vue.use(Aside)
    Vue.use(Main)
    Vue.use(Menu)
    Vue.use(MenuItem)
    Vue.use(Submenu)
    Vue.use(Breadcrumb)
    Vue.use(BreadcrumbItem)
    Vue.use(Card)
    Vue.use(Row)
    Vue.use(Col)
    Vue.use(Table)
    Vue.use(TableColumn)
    Vue.use(Switch)
    Vue.use(Tooltip)
    Vue.use(Pagination)
    Vue.use(Dialog)
    Vue.use(Tag)
    Vue.use(Tree)
    Vue.use(Select)
    Vue.use(Option)
    Vue.use(Cascader)
    Vue.use(Alert)
    Vue.use(Tabs)
    Vue.use(TabPane)
    Vue.use(Steps)
    Vue.use(Step)
    Vue.use(Checkbox)
    Vue.use(CheckboxGroup)
    Vue.use(RadioGroup)
    Vue.use(Upload)
    Vue.use(Loading )
    Vue.use(Timeline)
    Vue.use(TimelineItem)
    Vue.use(Image)

Vue.prototype.$message = Message
Vue.prototype.$confirm = MessageBox.confirm

let confirmD= function(msg){
    let f=  Vue.prototype.$confirm(msg, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
    })
    return f
}

Vue.prototype.$confirmD=confirmD
